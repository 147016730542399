import React, { Component, useEffect, useState } from 'react'
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import LandingPageContainer from './LandingPage/LandingPageContainer';
import FooterContainer from "./Footer/FooterContainer";
import HeaderContainer from "./Header/HeaderContainer";
import ErrorBoundary from './ErrorBoundary/index'
import NavMenuContainer from "./NavMenu/NavMenuContainer";
import CookieConsent, { Cookies } from "react-cookie-consent";


const HumaneFund = () => {
    const location = useLocation()
    const [slug, setSlug] = useState('/');
    let navigate = useNavigate();

    useEffect(() => {
        // Eggs/ => Eggs
        let hasTrailingSlash = location.pathname[location.pathname.length-1] === '/' && location.pathname !== '/'

        if (hasTrailingSlash){ 
            removeSlash = location.pathname.slice(0, -1)
            navigate(removeSlash)
        } 
      }, [location])

    // treat '/' as 'Home'  
    useEffect(() => {
      if (location.pathname === '/') { 
          setSlug('/Home') 
        } else {
            // /forms/womens-shelter-grant => womens-shelter-grant
            let slugPieces = location.pathname.split('/')
            let newSlug = slugPieces[slugPieces.length - 1]
            
            setSlug('/' + newSlug)
      }
    }, [location])
  
    let removeSlash = location.pathname.slice(0, -1)
    // Ace-Awards
    let hasUppercaseHyphenation = location.pathname.includes("-") && (location.pathname[1] === location.pathname[1].toLocaleUpperCase()) 

    return (
        <ErrorBoundary>
            <HeaderContainer />
            <NavMenuContainer />
            <Routes>
                <Route path ="/*" element={
                    // Ace-Awards => ace-awards
                    hasUppercaseHyphenation 
                        ? <Navigate to={location.pathname.toLowerCase()}/>
                        : <LandingPageContainer slug={slug}/> 
                        // ? hasTrailingSlash 
                        // : <Navigate to={removeSlash}/>
                    } 
                />
            </Routes> 
            <FooterContainer />
        </ErrorBoundary>
    )
}
export default HumaneFund
