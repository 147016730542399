import React, { Component } from 'react';

export class Onetrust extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // Dynamically add the script tag to the document
        const script = document.createElement('script');
        script.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
        script.type = "text/javascript";
        script.charset = "UTF-8";
        script.setAttribute("data-domain-script", "018eed7f-da1d-7bf8-a073-61147a9b816f");

        // Append the script to the head
        document.head.appendChild(script);
    }

    render() {
        // This component does not need to render anything itself
        return null;
    }
}

export default Onetrust;
