import React, { Component, useEffect } from 'react'
import SignUp, { SignUpForm } from '../Forms/SignUp'
import OnetrustEmbed, { Onetrust } from '../Footer/onetrust'
export class Footer extends Component {
    constructor(props) {
        super(props);
    }

   componentDidMount () {
    this.props.fetchPosts("footer")
   }

   componentDidUpdate (prevProps) {
       if (prevProps.footer !== this.props.footer && this.props.footer[0].acf.content[0]) { 
           this.setState({ footer: this.props.footer[0].acf.content[0] }) 
        }
   }

   render () {
    // this should just be a stateless component returning a landing page with footer prop
    let footer = this.props.footer
    let header = undefined

    if (this.props.footer && this.props.footer[0]) header = this.props.footer[0].acf.content

      return (
        <footer className="footer">
            <Onetrust />
            <div className="footer__container top">
                {header && header[0].footer_blocks.map((footer_block, index) => {
                    if (footer_block.footer_block_options.value == "wysiwig") {                        
                        return (
                        // if this is just text area and wysiwyg content:
                            <div className={`footer__block-container block-${index}`}>
                                <h5 className={`footer__block-header block-${index}`}>{footer_block.title}</h5>
                                {<span className="footer__content" dangerouslySetInnerHTML={{__html: footer_block.content}} />}
                            </div>
                        )
                    }

                    if (footer_block.footer_block_options.value == "form") {
                        // Display SignUp if toggled
                        return (
                            <div className={`footer__block-container block-${index}`}>
                                <h5 className={`footer__block-header block-${index}`}>{footer_block.title}</h5>
                                <SignUp />
                            </div>
                        )
                    }
                })}
            </div>
            
            <div className="footer__container bottom">
                {footer && (
                    <>
                        {<div className="footer__links" dangerouslySetInnerHTML={{__html: footer[0].acf.content[1].text}} />}
                    </>
                )}
            </div>
            <div class="footer_terms__container">
            This website uses cookies to enhance the user experience.{" "}
                <a href="/terms-of-use">Terms of Use</a>
                {" & "}
                <a href="/privacy-notice">Privacy Notice</a>
            </div>
        </footer>
        )
    }   
    
}
export default Footer
